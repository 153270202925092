import { useContext, useMemo } from "react";
import { AuthenticationContext } from "../services/authentication/authentication.provider";

export const useUserInformation = () => {
  const { actions, userInformation } = useContext(AuthenticationContext);

  const {
    customers,
    email,
    favorites,
    favoriteStations,
    isGlobalAdmin,
    isGlobalUser,
    mapToken,
    reports,
    shuntingFavoriteStationUicCode,
    subuserGroups,
    visit,
    userGroups,
    tracks,
    shuntingWindows,
    wagonLockTracks,
    selfShuntingTracks,
  } = userInformation || {};

  const isAdUser = Boolean(isGlobalAdmin || isGlobalUser);

  const isAdminInUserGroup = userGroups?.some(userGroup => userGroup.role === "admin");
  const isAdminInSubuserGroup = subuserGroups?.some(subuserGroup => subuserGroup.role === "admin");
  const filteredCustomers = useMemo(() => customers?.filter(customer => customer.mainUserGroupCustomer), [customers]);
  const mainCustomers = isAdUser ? customers : filteredCustomers;


  return {
    actions,
    customers,
    mainCustomers,
    email,
    favoriteStations,
    favorites,
    isAdminInUserGroup,
    isAdminInSubuserGroup,
    isAdUser,
    isGlobalAdmin,
    isGlobalUser,
    mapToken,
    reports,
    userGroups,
    shuntingFavoriteStationUicCode,
    subuserGroups,
    visit,
    tracks,
    shuntingWindows,
    wagonLockTracks,
    selfShuntingTracks,
  };
};